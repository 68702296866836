<template>
  <vue-modal @close="$emit('close')" ref="modal" size="lg">
    <template #title>Galería</template>
    <template #body>
        <div class="row justify-content-around">
          <FormText 
          v-model="items.socio"
          icon="fa-solid fa-tag"
          label="Socio"
          :rules="{ required: true }"
          :disabled="true"
        />
        <FormText 
          v-model="items.descripcion"
          icon="fa-solid fa-tag"
          label="Descripción"
          :rules="{ required: true }"
          :disabled="true"
        />
        <FormDate 
          v-model="items.fecha"
          icon="fa-solid fa-tag"
          label="Fecha de Evento"
          :rules="{ required: true }"
          :disabled="true"
        />
        </div>

          <div class="row justify-content-around flex-wrap">
            <div style="width: 150px">
              <FormSwitch
              v-model="galeria_img"
              label="Nueva Galeria?"
              icon="fa-solid fa-star"
              />
            </div>
            <label class="text-center">Restablecer Cambios<button class="btn btn-primary" type="button" @click="deleteNewGalery"><i class="fa-solid fa-rotate-right"></i></button></label>
          </div>
          <div class="d-flex flex-column justify-content-center flex-wrap align-items-center mb-3" style="min-height: 84px">
            <template v-if="galeria_img">
              
              <div class="row align-items-baseline">
                <FormText
                v-model="form.new_galeria"
                icon="fa-solid fa-tag"
                label="Nombre de la nueva Galeria"
                :rules="{ required: true }"
                />
                <button class="btn btn-primary button-crear-galeria ml-2" type="button" @click="createNewGaleria">Crear</button>
              </div> 
              <h6 class="text-center">Cree una Galeria y elimine las Imagenes que no desea guardar</h6>  
            </template>
            <template v-else>
              
              <div class="row align-items-center">
                <FormSelect 
                label="Galerías"
                icon="fa-solid fa-images"
                :options="listGalerias"
                v-model="form.selected_galeria"
                :rules="{ required: true }"
              />
              </div>
              <h6 class="text-center">Seleccione una Galeria y elimine las Imagenes que no desea guardar</h6>
            </template>
          </div>

        <div class="position-relative d-flex flex-wrap gap-3 pl-6 pr-6 justify-content-around mb-3" v-if="images.length">
          <button @click="paginate('img','-')" :disabled="show_img.back_move" type="button" class="btn btn-outline-primary position-absolute button-back-img"><i class="fa-solid fa-circle-chevron-left"></i></button>
          <button @click="paginate('img','+')" type="button" :disabled="show_img.forward_move" class="btn btn-outline-primary position-absolute button-forward-img"><i class="fa-solid fa-circle-chevron-right"></i></button>
            <template v-for="img in images" :key="img">
              <div class="style-galeria-img position-relative">
                <img :src="img.url" alt="">
                 <i class="fa-solid fa-trash icon-trash-delete-img position-absolute" @click="onDelete('img',img)"></i>
                 <i class="fa-solid fa-magnifying-glass icon-search-img position-absolute" @click="onZoom('img', img)"></i>
              </div>
            </template>
        </div>

        <div class="position-relative d-flex flex-wrap gap-3 pl-6 pr-6 justify-content-around mb-3"  v-if="videos.length">
          <button @click="paginate('video','-')" :disabled="show_video.back_move" type="button" class="btn btn-outline-primary position-absolute button-back-video"><i class="fa-solid fa-circle-chevron-left"></i></button>
          <button @click="paginate('video','+')" type="button" :disabled="show_video.forward_move" class="btn btn-outline-primary position-absolute button-forward-video"><i class="fa-solid fa-circle-chevron-right"></i></button>
            <template v-for="video in videos" :key="video">
              <div class="style-galeria-video position-relative">
                <video :src="video.url" autoplay muted loop></video>
                 <i class="fa-solid fa-trash icon-trash-delete-video position-absolute" @click="onDelete('video',video)"></i>
                 <i class="fa-solid fa-magnifying-glass icon-search-video position-absolute" @click="onZoom('video', video)"></i>
              </div>
            </template>
        </div>

        <div v-if="zoom" class="position-fixed zoom-items" @click="onZoom('close', $event)">
          <img class="position-absolute zoom-items-img" v-if="img_zoom" :src="img_zoom" alt="">
          <video id="video-zoom" class="position-absolute zoom-items-video" v-if="video_zoom" :src="video_zoom" controls></video>
        </div>

    </template>
    <template #footer>
      <button class="btn btn-primary" @click="saveData">Guardar</button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormDate from "@/components/Form/FormDate.vue";
import FormSwitch from "@/components/Form/FormSwitch.vue";
import FormSelect from "@/components/Form/FormSelect.vue";

export default {
  components: {

    FormText,
    FormDate,
    FormSwitch,
    FormSelect,
},
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    form: {
      new_galeria_id: null,
      new_galeria: null,
      selected_galeria: null,
    },
    show_img:{
      initial: 0,
      final: 3,
      paginate_move: 3,
      back_move: true,
      forward_move: true,
    },
    show_video:{
      initial: 0,
      final: 3,
      paginate_move: 3,
      back_move: true,
      forward_move: true,
    },
    delete_item_img: false,
    delete_item_video: false,
    img_zoom: null,
    video_zoom: null,
    zoom: false,
    galeria_img: true,
  }),
  watch:{
    delete_item_img(){
      if(this.$store.getters.filesPublicos.file_img_publicas.length <= this.show_img.initial){
        if (this.show_img.initial !== 0){
          this.show_img.initial -= this.show_img.paginate_move
          this.show_img.final -= this.show_img.paginate_move
          if(this.show_img.initial === 0){
            this.show_img.back_move = true   
          } else{
            this.show_img.back_move = false
          }
        }
      }
      if (this.$store.getters.filesPublicos.file_img_publicas.length > this.show_img.final){
          this.show_img.forward_move = false
        } else{
          this.show_img.forward_move = true
        }
    },
    delete_item_video(){
      if(this.$store.getters.filesPublicos.file_video_publicas.length <= this.show_video.initial){
        if (this.show_video.initial !== 0){
          this.show_video.initial -= this.show_video.paginate_move
          this.show_video.final -= this.show_video.paginate_move
          if(this.show_video.initial === 0){
            this.show_video.back_move = true   
          } else{
            this.show_video.back_move = false
          }
        }
      }
      if (this.$store.getters.filesPublicos.file_video_publicas.length > this.show_video.final){
          this.show_video.forward_move = false
        } else{
          this.show_video.forward_move = true
        }
    }
  },
  computed: {
    listGalerias(){
      return this.$store.getters.listaGalerias.map(c => ({value: c.id, name: c.nombre})) || []
    },
    images(){
      return this.$store.getters.filesPublicos.file_img_publicas?.slice(this.show_img.initial, this.show_img.final) || []
    }, 
    videos(){
      return this.$store.getters.filesPublicos.file_video_publicas?.slice(this.show_video.initial, this.show_video.final) || []
    },
    items() {
      return this.$store.getters.filesPublicos;
    },
  },
  async mounted() {
    await this.$store.dispatch('getListaGalerias')
    await this.getItems();
    if (this.$store.getters.filesPublicos.file_img_publicas.length > 3){
      this.show_img.forward_move = false
    }
    if (this.$store.getters.filesPublicos.file_video_publicas.length > 3){
      this.show_video.forward_move = false
    }
  },
  methods: {
    async deleteNewGalery(){
      
      if(this.form.new_galeria_id){
        this.$alert
        .fireConfirm(`Borrara La Galeria Creada ${this.form.new_galeria}`, "Esta seguro que desea continuar?")
        .then(async() => {
         await this.$store.dispatch("deleteGaleria",this.form.new_galeria_id)
        })
        .then(()=>{
          this.form.new_galeria_id = null,
          this.form.new_galeria = null,
          this.form.selected_galeria = null
            this.reloadPage()
          });
      }
      this.form.selected_galeria = null
      this.reloadPage()
    },
    async reloadPage(){
      await this.getItems()
      this.show_img.initial= 0
      this.show_img.final= 3
      this.show_video.initial= 0
      this.show_video.final= 3
      if (this.$store.getters.filesPublicos.file_img_publicas.length > 3){
      this.show_img.forward_move = false
      }
      if (this.$store.getters.filesPublicos.file_video_publicas.length > 3){
        this.show_video.forward_move = false
      }
    },
    close() {
      this.$refs.modal.close();
    },
    async getItems() {
      await this.$store.commit("CLEAR_FILES_PUBLICOS");
      if (this.id){
        await this.$store.dispatch("getFilesPublicos", {id: this.id});
      }
      await this.$store.dispatch('getListaGalerias')
    },
    async createNewGaleria(){
      let form = {nombre: this.form.new_galeria}
      await this.$store.dispatch("createGaleria",form)
      .then(({data})=>{
        this.form.new_galeria_id = data.galeria.id,
        this.form.selected_galeria = null
      })
      .finally(()=>{
        this.$store.dispatch('getListaGalerias')
      })
    } ,
    paginate(type,value){
      if(type === 'img'){
        if (value === '-'){
          if(this.show_img.initial !== 0){
            this.show_img.initial -= this.show_img.paginate_move
            this.show_img.final -= this.show_img.paginate_move
            if (this.show_img.initial !== 0){
              if(this.images.length <= this.show_img.final){
              return this.show_img.forward_move = true, this.show_img.back_move = false
            }
            return this.show_img.forward_move = false,this.show_img.back_move = false
            }
            return this.show_img.back_move = true,this.show_img.forward_move = false
          }
        }
        if (value === '+'){
          this.show_img.initial += this.show_img.paginate_move
          this.show_img.final += this.show_img.paginate_move
          if(this.images.length <= this.show_img.final){
            return this.show_img.forward_move = true, this.show_img.back_move = false
          }
          return this.show_img.forward_move = false,this.show_img.back_move = false
        }
      }
      if(type === 'video'){
        if (value === '-'){
          if(this.show_video.initial !== 0){
            this.show_video.initial -= this.show_video.paginate_move
            this.show_video.final -= this.show_video.paginate_move
            if (this.show_video.initial !== 0){
              if(this.images.length <= this.show_video.final){
              return this.show_video.forward_move = true, this.show_video.back_move = false
            }
            return this.show_video.forward_move = false,this.show_video.back_move = false
            }
            return this.show_video.back_move = true,this.show_video.forward_move = false
          }
        }
        if (value === '+'){
          this.show_video.initial += this.show_video.paginate_move
          this.show_video.final += this.show_video.paginate_move
          if(this.images.length <= this.show_video.final){
            return this.show_video.forward_move = true, this.show_video.back_move = false
          }
          return this.show_video.forward_move = false,this.show_video.back_move = false
        }
      }
    },
    onDelete(type,item){
      if (type === 'img'){
        return this.delete_item_img = !this.delete_item_img,this.$store.getters.filesPublicos.file_img_publicas = this.$store.getters.filesPublicos.file_img_publicas.filter(x=> x !== item)
      }
      if (type === 'video'){
        return this.delete_item_video = !this.delete_item_video,this.$store.getters.filesPublicos.file_video_publicas = this.$store.getters.filesPublicos.file_video_publicas.filter(x=> x !== item)
      }
    },
    onZoom(type, item){
      if(type === 'img'){
        return this.img_zoom = item.url, this.zoom = true
      }
      if(type === 'video'){
        return this.video_zoom = item.url, this.zoom = true
      }
      if(type === 'close'){
        if( item.target.id !== 'video-zoom')
        {return this.img_zoom = null, this.video_zoom = null, this.zoom = false}
      }
    },
    async saveData(){
      if(!this.form.new_galeria_id && !this.form.selected_galeria){
        return this.$toast.error("Debe Seleccionar o Crear Una Galeria")
      }
      let id = this.form.new_galeria_id || this.form.selected_galeria
      let form = {}
      form.img = this.$store.getters.filesPublicos.file_img_publicas
       await this.$store.dispatch('moveFilesPublicas',{id, form})
       .then(()=>{
        this.$emit('saved')
        this.close();
       })
    }
  },
};
</script>
<style lang="scss" scoped>
.button-back-img, .button-back-video{
  z-index: 5;
  left: 0%;
  top: 50%;
  transform: translateX(0%) translateY(-50%);
  filter: drop-shadow(3px 5px 2px rgb(0, 0, 0 / 0.4));
}
.button-forward-img, .button-forward-video{
  z-index: 5;
  right: 0%;
  top: 50%;
  transform: translateX(0%) translateY(-50%);
  filter: drop-shadow(3px 5px 2px rgb(0, 0, 0 / 0.4));
}

.style-galeria-img{
  height: 150px;
  width: 250px;
}
.style-galeria-video{
  height: 150px;
  width: 250px;
}
.style-galeria-img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
   cursor: pointer;
}
.style-galeria-video video{
  height: 100%;
  width: 100%;
  object-fit: cover;
   cursor: pointer;
}
.style-galeria-img:hover > img, .style-galeria-video:hover > video{
  filter: blur(10px);
}
.style-galeria-img:hover > .icon-trash-delete-img, .style-galeria-video:hover > .icon-trash-delete-video,
.style-galeria-img:hover > .icon-search-img, .style-galeria-video:hover > .icon-search-video{
  z-index: 1;
}

.icon-trash-delete-img, .icon-trash-delete-video{
  font-weight: 1000;
  border-radius: 50%;
  border: 4px solid var(--color-primary);
  padding: 1em;
  // position: relative; 
  left: 50%;
  top: 50%;
  color: var(--color-primary);
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
  transition: all 0.5s;
}
.icon-trash-delete-img:hover, .icon-trash-delete-video:hover, .icon-search-img:hover, .icon-search-video:hover{
  color: white;
  border: 4px solid white;
  background-color: var(--color-primary);
  scale: 1.2;
  filter: drop-shadow(3px 5px 2px rgb(0, 0, 0 / 0.4));
}

.icon-search-img, .icon-search-video{
  font-weight: 1000;
  border-radius: 50%;
  border: 4px solid var(--color-primary);
  padding: 1em;
  // position: relative; 
  right: 0%;
  top: 0%;
  color: var(--color-primary);
  transform: translateX(0%) translateY(0%);
  z-index: -1;
  transition: all 0.5s;
}

.zoom-items{
  z-index: 5;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: grid;
  place-items: center;
}
.zoom-items-img{
  max-height: 70vh;
  max-width: 70vw;
}
.zoom-items-video{
  max-height: 50vh;
  max-width: 50vw;
}


</style>